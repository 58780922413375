import PropTypes from 'prop-types'
import React from 'react'

import ButtonGroup from './core/buttons/ButtonGroup'
import * as Buttons from '../dashboard/src/components/blocks/Buttons'
import Modals from './core/modals/Modals'
import {TextGutterMedium} from '../dashboard/src/components/blocks/Texts'

import AppDispatcher from '../lib/ep-dispatcher'
import {railsUrl} from '../lib/urlTools'
import routerUtils from '../lib/routerUtils'

import './timeoutModal.scss'

const EVENTS = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']
const INACTIVITY_TIMER_DURATION = 12 * 60 * 1000 // 12 minutes in ms
const MODAL_COUNTDOWN_DURATION = 3 * 60 * 1000 // 3 minutes in ms

export default class TimeoutModal extends React.Component {
  constructor() {
    super()

    this.state = {
      remainingInactivityTime: INACTIVITY_TIMER_DURATION,
      remainingModalCountdownTime: MODAL_COUNTDOWN_DURATION,
      showModal: false,
      tabInactiveStartTime: null
    }

    this.constructCountdownString = this.constructCountdownString.bind(this)
    this.handleUserActivity = this.handleUserActivity.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
    this.logOut = this.logOut.bind(this)
    this.modalCountdown = this.modalCountdown.bind(this)
    this.restartInactivityTimer = this.restartInactivityTimer.bind(this)
    this.restartModalCountdownCountdown = this.restartModalCountdown.bind(this)
    this.showTimeoutModal = this.showTimeoutModal.bind(this)
    this.stayLoggedIn = this.stayLoggedIn.bind(this)

    this.dispatcherToken = AppDispatcher.register(this.handleUserActivity)
  }

  componentDidMount() {
    EVENTS.forEach(event => {
      document.addEventListener(event, this.handleUserActivity)
    })

    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    if (this.props.needsCheck)
      this.startInactivityTimer()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextState.showModal !== this.state.showModal || nextState.showModal)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.needsCheck && !this.props.needsCheck)
      this.startInactivityTimer()
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this.dispatcherToken) // Stop trying to set state if not on the DOM. --BLR

    clearTimeout(this.inactivityTimeout)
    clearInterval(this.modalCountdownInterval)

    EVENTS.forEach(event => {
      document.removeEventListener(event, this.handleUserActivity)
    })

    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }

  handleVisibilityChange() {
    const now = Date.now()

    if (!document.hidden) {
      const timeAwayFromTab = now - this.state.tabInactiveStartTime

      if (this.state.showModal) {
        this.restartModalCountdown(timeAwayFromTab)
      } else {
        if (timeAwayFromTab >= this.state.remainingInactivityTime + MODAL_COUNTDOWN_DURATION)
          this.logOut()

        this.restartInactivityTimer(timeAwayFromTab)
      }
    } else {
      this.setState({tabInactiveStartTime: now})
      clearTimeout(this.inactivityTimeout)
      clearInterval(this.modalCountdownInterval)
    }
  }

  restartInactivityTimer(timeAwayFromTab) {
    const newRemainingInactivityTime = this.state.remainingInactivityTime - timeAwayFromTab

    if (newRemainingInactivityTime <= 0) {
      this.setState({
        remainingModalCountdownTime: MODAL_COUNTDOWN_DURATION + newRemainingInactivityTime
      })
      this.showTimeoutModal()
    } else {
      this.setState({remainingInactivityTime: newRemainingInactivityTime},
        () => this.startInactivityTimer()
      )
    }
  }

  restartModalCountdown(timeAwayFromTab) {
    this.setState(prevState => ({
      remainingModalCountdownTime: Math.max(prevState.remainingModalCountdownTime - timeAwayFromTab, 0)
    }), () => {
      if (this.state.remainingModalCountdownTime <= 0)
        this.logOut()
      else
        this.modalCountdownInterval = setInterval(this.modalCountdown, 1000)
    })
  }

  startInactivityTimer() {
    this.inactivityTimeout = setTimeout(() => {
      this.showTimeoutModal()
    }, this.state.remainingInactivityTime)
  }

  handleUserActivity() {
    this.setState({remainingInactivityTime: INACTIVITY_TIMER_DURATION})
  }

  modalCountdown() {
    this.setState(prevState => ({remainingModalCountdownTime: Math.ceil(prevState.remainingModalCountdownTime - 1000)}),
      () => {
        if (this.state.remainingModalCountdownTime <= 0)
          this.logOut()
      })
  }

  showTimeoutModal() {
    clearTimeout(this.inactivityTimeout)

    this.setState({showModal: true})

    this.modalCountdownInterval = setInterval(this.modalCountdown, 1000)
  }

  logOut() {
    this.setState({showModal: false})

    clearInterval(this.modalCountdownInterval)

    this.props.signoutCallback()
  }

  stayLoggedIn() {
    clearInterval(this.modalCountdownInterval)

    this.setState({
      remainingModalCountdownTime: MODAL_COUNTDOWN_DURATION,
      showModal: false
    })

    this.startInactivityTimer()
  }

  constructCountdownString() {
    const modalSecondsLeft = Math.ceil(this.state.remainingModalCountdownTime / 1000)

    // Prevent awkward flicker of an apparent sentence fragment during actual logout.
    if (modalSecondsLeft < 0) return 'a moment'

    let countdownString = ''
    const minutes = Math.floor(modalSecondsLeft / 60)
    const seconds = modalSecondsLeft % 60

    switch (minutes) {
      case 0:
        break
      case 1:
        countdownString += '1 minute'
        break
      default:
        countdownString += `${minutes} minutes`
        break
    }

    if (minutes > 0 && seconds > 0)
      countdownString += ' and '

    switch (seconds) {
      case 0:
        break
      case 1:
        countdownString += '1 second'
        break
      default:
        countdownString += `${seconds} seconds`
        break
    }

    return countdownString
  }

  render() {
    return (
      <Modals.PopUpModal className='timeout-modal' showCloser={false} showModal={this.state.showModal}>
        <h2>Are you still using Everplans?</h2>
        <TextGutterMedium>
          We have not detected any activity from you recently. To keep your account secure,
          we will automatically log you out in {this.constructCountdownString()}.
        </TextGutterMedium>
        <ButtonGroup>
          <Buttons.Cancel onClick={this.logOut}>Log out</Buttons.Cancel>
          <Buttons.Accept onClick={this.stayLoggedIn}>Stay logged in</Buttons.Accept>
        </ButtonGroup>
      </Modals.PopUpModal>
    )
  }
}

TimeoutModal.defaultProps = {
  signoutCallback: () => { routerUtils.setLocation(railsUrl('sign_out?msg=timeout')) }
}

TimeoutModal.propTypes = {
  needsCheck: PropTypes.bool,
  signoutCallback: PropTypes.func,
  signoutLink: PropTypes.func
}
